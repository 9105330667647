html {
    min-height: 100vh;
    min-height: 100svh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
    background: black;
}

body {
    margin: 0;
    font-family: 'Didot';
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-user-drag: none;
    background: linear-gradient(90deg, #202020, 60vw, transparent);
    overflow: clip;
}

#root {
    color: white;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: 100dvh;
    width: 100%;
    box-sizing: border-box;
    position: relative;
}

a {
    text-decoration: none;
    color: initial;
}
